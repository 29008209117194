import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Button from 'react-bootstrap/Button'
import SEO from "../components/seo"

import SNS from "../images/sns-icon.png"
import Blog from "../images/blog-icon.png"

const IndexPage = () => (
    <Layout>
        <SEO />
            <div className="limited"><span><span className="big">2024</span>年度、宝塚音楽学校<span className="big">112</span>期生<span className="big">2</span>名合格</span></div>        <div className="hero">
            <div className="icons">
                <a href="https://www.instagram.com/yukimamiya.ballet_studio" target="_blank" rel="noreferrer"><img className="icons-img" src={SNS} alt="Instagram" /></a>
                <a href="https://ameblo.jp/yuki-mamiya-ballet/" target="_blank" rel="noreferrer"><img className="icons-img" src={Blog} alt="Ameblo" /></a>
            </div>
            <div className="hero-text">
                <h1>YUKI MAMIYA BALLET STUDIO</h1>
                <h6>都立大学駅からすぐのバレエ教室</h6>
                <h6>宝塚音楽学校受験対応</h6>
                <Button variant="outline-dark" href="/inquiry" size="lg">体験レッスンの<br />お申し込みはこちら</Button>
            </div>
            <StaticImage
                src="../images/pexels-ron-lach-6715560_large.jpg"
                layout="constrained"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="top site"
                style={{ margin: `0`, padding: `0` }}
                className="hero-image-pc"
            />
            <StaticImage
                src="../images/pexels-photo-6714219.jpeg"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="top site"
                style={{ margin: `0`, padding: `0` }}
                className="hero-image-sp"
            />
        </div>

        <div className="index-contents">
            <div className="about">
                <p>このスタジオは、子ども達がバレエを通して大切なことを学びながらのびのびと成長してほしい、また宝塚音楽学校やミュージカル科などを進路に考えている初心者の中高生も、基礎からレッスンができる環境でサポートしていきたいという思いで開設いたしました。<br />
                    3歳からシニアの方まで、楽しく長くバレエを続けて頂きたいと思います。<br />
                    ぜひ体験レッスンにお越しください！</p>
            </div>

            <div className="course">
                <div className="class-menu-wrapper">
                    <div className="class-menu">
                        <div className="label">各クラスのご案内</div>
                        <Button variant="outline-light" size="lg" href="/child">子どもクラス</Button>
                        <Button variant="outline-light" size="lg" href="/adult">大人クラス</Button>
                        <Button variant="outline-light" size="lg" href="/takarazuka">宝塚受験クラス</Button>
                    </div>
                </div>
            </div>

            <div className="info">
                <Button variant="outline-dark" href="/studio" size="lg">アクセス・スタジオ情報</Button>
                <Button variant="outline-dark" href="/company" size="lg">会社概要</Button>
            </div>
        </div>
    </Layout >
)

export default IndexPage
